import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Image, { GatsbyImageProps } from "gatsby-image";

const w = 616;
const h = 580;

const Container = Styled.div`
  position: relative;
  height: 0;
  width: ${w}px;
  max-width: 100%;
  padding-bottom: ${Math.round((h / w) * 100)}%;
  margin: 0 auto;
  
  ${Media.lessThan("1024px")`
    width: 34.5rem;
  `}

  ${Media.greaterThan("medium")`
    margin: 0;
  `}
`;

const Content = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Video = Styled.video`
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 1;

  ${Media.lessThan("1024px")`
    width: 34.5rem;
  `}

`;

const ImageA = Styled(Image).attrs({
  style: {
    position: "absolute",
  },
})`
  position: absolute;
  width: 100%;
  top: 50%;
  right: 0%;
  transform: translate(0%, -50%);
  z-index: 0;

  ${Media.lessThan("1024px")`
    width: 34.5rem;
  `}
`;

type HomeBannerIllustrationProps = {
  imageA: GatsbyImageProps;
  videoName?: string;
};

const HomeBannerIllustration = ({
  imageA,
  videoName,
}: HomeBannerIllustrationProps) => (
  <Container>
    <Content>
      <Video autoPlay muted loop playsInline>
        <source
          src={
            videoName == undefined
              ? "/videos/platform-presentation-2022.mp4"
              : "/videos/" + videoName + ".mp4"
          }
          type="video/mp4"
        />
        <source
          src={
            videoName == undefined
              ? "/videos/platform-presentation-2022.ogv"
              : "/videos/" + videoName + ".ogv"
          }
          type="video/ogv"
        />
        <source
          src={
            videoName == undefined
              ? "/videos/platform-presentation-2022.webm"
              : "/videos/" + videoName + ".webm"
          }
          type="video/webm"
        />
        <img
          src={
            videoName == undefined
              ? "/videos/platform-presentation-2022.png"
              : "/videos/" + videoName + ".png"
          }
          alt="Uizard presentation hero video"
        />
      </Video>
      <ImageA
        fluid={"fluid" in imageA ? imageA.fluid : null}
        alt="Uizard presentation hero image"
      />
    </Content>
  </Container>
);

export default HomeBannerIllustration;
