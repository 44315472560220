import * as React from "react";
import Styled from "styled-components/macro";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import Meta from "../components/meta";
import Layout from "../components/layout/Layout";
import HomeBanner from "../components/pages/home/HomeBanner";
import HomeFeatured from "../components/pages/home/HomeFeatured";
import HomeExamples from "../components/pages/home/HomeExamples";
import HomeWireframe from "../components/pages/home/HomeWireframe";
import HomeAutodesigner from "../components/pages/home/HomeAutodesigner";
import HomeIdeationSquare from "../components/pages/home/HomeIdeationSquare";
import FAQSection from "../components/pages/faq/FAQSection";
import Shoutout from "../components/pages/signup/Shoutout";
import Content from "../components/layout/Content/Content";

const ContentExtended = Styled(Content)`
  margin-top: 6rem;
`;

const Home = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Meta
        title="Uizard | App, Web, & UI Design Made Easy | Powered By AI"
        description="Design mobile apps, websites, and UIs in minutes with Uizard, the world's first AI-powered UX/UI design tool! Sign up today."
        url="/"
        faqschema='{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "What is Uizard used for?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Uizard is a rapid, AI-powered UI design tool for designing wireframes, mockups, and prototypes in minutes. Uizard&apos;s AI features allow users to generate UI designs from text prompts, convert hand-drawn sketches into wireframes, and transform screenshots into editable designs. Uizard has a whole host of pre-made design templates and UI components to support rapid and collaborative ideation and iteration."
              }
            },
            {
              "@type": "Question",
              "name": "Who uses Uizard?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Uizard is built for non-designers and designers alike to help streamline design and collaboration. Uizard is used by a wide range of people, from founders looking to build their app or web design from the ground up, to marketing agencies and product managers who rely on Uizard&apos;s ease of use and intuitive functionality to communicate designs with a wide range of stakeholders."
              }
            },
            {
              "@type": "Question",
              "name": "How to use Uizard?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Using Uizard is easy. Simply sign up for free and start designing. Uizard comes packed with free-to-use app and web UI templates that you can tweak to fit your own design. Or you can start your design from scratch and build it using Uizard&apos;s pre-made components. You can also use Design Assistant to transform your hand-drawn wireframes into digital designs in seconds."
              }
            },
            {
              "@type": "Question",
              "name": "Is Uizard Free?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Uizard is free to use. Free signup gives you access to the Uizard platform as well as several web and app UI templates and UX/UI components. Essentially, you can start realizing your design on a screen in front of you for free, right now. Uizard also offers a paid pricing plan, which unlocks extra features and more of the platform&apos;s amazing pre-made design templates and components."
              }
            },
            {
              "@type": "Question",
              "name": "Is Uizard good?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Community is extremely important at Uizard. The Uizard team are committed to ensuring that the app runs as smoothly as possible and includes all the features that you need to successfully bring your wireframe or prototype design to life. Uizard currently rates as 4.6 stars on Capterra and Software Advice, with many users citing Uizard as a great tool for designing wireframes and prototypes rapidly, and for launching products faster."
              }
            }
          ]
        }'
      />

      <HomeBanner imageA={data.bannerImageBig.childImageSharp} />

      <HomeFeatured text="Trusted by individuals and teams at the world's boldest companies" />

      <HomeAutodesigner
        tagline="UI Design Tool"
        headline="Limitless and effortless design"
        description="Never leave an idea behind... Generate mockups from text prompts, scan screenshots of apps or websites, and drag and drop UI components to bring your vision to life."
        description2="Uizard makes UI design accessible to all, empowering you to design with no barriers."
        imageA={data.autodesignerImageA.childImageSharp}
        imageB={data.autodesignerImageB.childImageSharp}
        imageAlt="turn text prompts into UI designs with AI"
        videoName="uizard-autodesigner-light"
        link=""
      />

      <HomeWireframe
        taglineh2="AI Design Tool"
        headline="Your projects, streamlined"
        description="Gone are the days of waiting for external teams to make time for your ideas. Leverage Uizard's powerful AI technology to design faster and more intuitively than ever before."
        description2="Going from idea, to mockup, to clickable prototype has never been so easy."
        imageA={data.wireframeScanImageA.childImageSharp}
        imageB={data.wireframeScanImageB.childImageSharp}
        imageAlt="hand-drawn sketches into beautiful designs automatically."
        videoName="uizard-wireframe-scan"
        link=""
      />

      <HomeIdeationSquare
        taglineh2="Productivity Tool"
        headline="Collaborate in real time"
        description="Work in real time alongside your team and invite stakeholders to review and feedback on your design ideas."
        description2="Uizard is the ultimate productivity hack, allowing you to move projects forward without waiting months for design resource"
        imageAlt="screenshot style extraction to your project automatically"
        imageA={data.collaborationA.childImageSharp}
        imageB={data.collaborationB.childImageSharp}
        videoName="uizard-mockups-rapid-mockup"
        link=""
      />

      <HomeExamples />

      <FAQSection
        title={"Frequently Asked Questions"}
        questions={[
          {
            question: "What is Uizard used for?",
            answer:
              "Uizard is a rapid, AI-powered UI design tool for designing wireframes, mockups, and prototypes in minutes. Uizard's AI features allow users to generate UI designs from text prompts, convert hand-drawn sketches into wireframes, and transform screenshots into editable designs. Uizard has a whole host of pre-made design templates and UI components to support rapid and collaborative ideation and iteration.",
          },
          {
            question: "Who uses Uizard?",
            answer:
              "Uizard is built for non-designers and designers alike to help streamline design and collaboration. Uizard is used by a wide range of people, from founders looking to build their app or web design from the ground up, to marketing agencies and product managers who rely on Uizard's ease of use and intuitive functionality to communicate designs with a wide range of stakeholders.",
          },
          {
            question: "How to use Uizard?",
            answer:
              "Using Uizard is easy. Simply sign up for free and start designing. Uizard comes packed with free-to-use app and web UI templates that you can tweak to fit your own design. Or you can start your design from scratch and build it using Uizard's pre-made components. You can also use Design Assistant to transform your hand-drawn wireframes into digital designs in seconds.",
          },
          {
            question: "Is Uizard Free?",
            answer:
              "Uizard is free to use. Free signup gives you access to the Uizard platform as well as several web and app UI templates and UX/UI components. Essentially, you can start realizing your design on a screen in front of you for free, right now. Uizard also offers a paid pricing plan, which unlocks extra features and more of the platform's amazing pre-made design templates and components.",
          },
          {
            question: "Is Uizard good?",
            answer:
              "Community is extremely important at Uizard. The Uizard team are committed to ensuring that the app runs as smoothly as possible and includes all the features that you need to successfully bring your wireframe or prototype design to life. Uizard currently rates as 4.6 stars on Capterra and Software Advice, with many users citing Uizard as a great tool for designing wireframes and prototypes rapidly, and for launching products faster.",
          },
        ]}
      />

      <ContentExtended>
        <Shoutout
          headline="Design made easy"
          description="Anyone can have a great idea... now everyone can bring them to life."
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </ContentExtended>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    bannerImageBig: file(
      relativePath: { eq: "home/platform-presentation-2022-2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageA: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageB: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeScanImageA: file(
      relativePath: { eq: "home/uizard-wireframe-scan-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    wireframeScanImageB: file(
      relativePath: { eq: "home/uizard-wireframe-scan-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    collaborationA: file(
      relativePath: { eq: "mockups/Mockups_RapidMockup_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    collaborationB: file(
      relativePath: { eq: "mockups/Mockups_RapidMockup_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
