import * as React from "react";
import constants from "constants/index";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { GatsbyImageProps } from "gatsby-image";
import Description from "../../../texts/Description";
import Content from "../../../layout/Content/Content";
import HomeBannerIllustration from "../HomeBannerIlustration";
import Button from "../../../buttons/Button";
// @ts-ignore
import Headline from "../../../texts/Headline";
import HomeBannerAnimation from "../HomeBannerAnimation";
import MediaQuery from "../../../layout/MediaQuery";

const Container = Styled.div`
  margin: 10rem 0 3rem 0;
  overflow: hidden; // fix for columns overflow

  ${Media.greaterThan("medium")`
    margin: 15rem 0 8rem 0;
  `}

  ${Media.greaterThan("large")`
    margin: 10rem 0 5rem 0;
  `}
`;

const Columns = Styled.div`
  display: flex;
  flex-direction: column;

  ${Media.greaterThan("700px")`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`;

const Column = Styled.div`
  ${Media.greaterThan("700px")`
    min-width: 0;
  `}
`;

const ColumnText = Styled(Column)`
  margin-bottom: 4rem;
  padding-right: 0;

  ${Media.between("700px", "large")`
    width: 50%;
  `}
  
  ${Media.greaterThan("large")`
    margin-bottom: 0;
  `}
`;

const ColumnIllustration = Styled(Column)`

  ${Media.greaterThan("large")`
    padding: 0 0 0 6rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `}
`;

const Header = Styled.div`
  width: 43rem;
  max-width: 100%;
  margin: 0 auto;
  
  ${Media.lessThan("medium")`
    padding-right: 1.2rem;
  `}

  ${Media.greaterThan("medium")`
    margin: 0;
  `}

  ${Media.greaterThan("large")`
    width: calc(54rem);
    padding-left: 9.5rem;
  `}
`;

const HeadlineWrapper = Styled.div`
  max-width: 100%;
  margin: 0;
  
  svg {
    max-width: 100%;
  }

  ${Media.greaterThan("medium")`
    margin: 0
  `}
`;

const DescriptionExtended = Styled(Description)`
  width: 28.5rem;
  margin-bottom: 3rem;

  ${Media.greaterThan("700px")`
    width: auto;
    margin-bottom: 3.5rem;
  `}
`;

const SignupContainer = Styled.div`
  max-width: 43rem;
`;

const ExtendedMediaQuery = Styled(MediaQuery)`
  height: 0;
`;

const ExtendedHeadline = Styled(Headline)`
  font-size: 3.6rem;
  line-height: 3.8rem;
  max-width: 100%;
  margin: 0 0 2rem 0;
  
  ${Media.greaterThan("medium")`
    width: auto;
    text-align: left;
    margin: 0 0 3rem 0;
  `}

  ${Media.greaterThan("large")`
    font-size: 5.6rem;
    line-height: 6.5rem;
  `}
`;

const NavButtonLink = Styled.a`
  margin-right: 2rem;
`;

type HomeBannerProps = {
  imageA: GatsbyImageProps;
};

const HomeBanner = ({ imageA }: HomeBannerProps) => (
  <Container>
    <Content>
      <Columns>
        <ColumnText>
          <Header>
            <HeadlineWrapper>
              <ExtendedHeadline as="h1">
                Design stunning <br />
                <HomeBannerAnimation
                  words={[
                    "mockups",
                    "mobile apps",
                    "websites",
                    "web apps",
                    "wireframes",
                    "prototypes",
                    "interfaces",
                  ]}
                />{" "}
                <br />
                in minutes.
              </ExtendedHeadline>
            </HeadlineWrapper>

            <DescriptionExtended>
              The world's easiest-to-use design and ideation tool - powered by AI
              <br />
              <br />
              No design experience required!
            </DescriptionExtended>

            <NavButtonLink
              href={`https://${constants.APP_DOMAIN}/auth/sign-up`}
            >
              <Button solid>Sign up for free</Button>
            </NavButtonLink>

            {/*
            <NavButtonLink href="https://calendly.com/rizwan-uizard/uizard-demo" target="_blank">
              <Button
                variant='white'
                solid={1}
              >
                Book a Demo
              </Button>
            </NavButtonLink>*/}
          </Header>
        </ColumnText>

        <ColumnIllustration>
          <HomeBannerIllustration imageA={imageA} />
        </ColumnIllustration>
      </Columns>
    </Content>
  </Container>
);

export default HomeBanner;
