import * as React from "react";
import Styled from "styled-components/macro";
import { useEffect, useState } from "react";
import Media from "@uizard-io/styled-media-query";

const Container = Styled.span`
  max-width: 43rem;
  position: relative;
`;

const Text = Styled.span`
  z-index: 5;
  position: relative;
  
  &:before {
  content: '';
  position: absolute;
  height: 5.7rem;
  width: 100%;
  }
`;

const TextCursor = Styled.span`
  border-right: .05em solid;
  animation: caret 1.3s steps(1) infinite;
  height: 4.5rem;
  display: inline-block;
  transform: translateY(1rem);
  
  ${Media.greaterThan("large")`
    height: 5.7rem;
  `}
  
  @keyframes caret {
    50% {
      border-color: transparent;
    }
  }
`;

type HomeBannerAnimationProps = {
  words?: string[];
};

const HomeBannerAnimation = ({ words }: HomeBannerAnimationProps) => {
  const wordsToAnimate =
    words == undefined
      ? [
          "non-designers",
          "entrepreneurs",
          "founders",
          "disrupters",
          "consultants",
          "leaders",
          "managers",
          "innovators",
          "hustlers",
        ]
      : words;

  let wordPosition = Math.floor(Math.random() * (wordsToAnimate.length - 1));

  const [wordAnimating, setWordAnimating] = useState([""]);
  let typingStatus = "typing";
  const acceleration = 0.75;
  const veryFastTimer = 50 * acceleration;
  const fastTimer = 75 * acceleration;
  const longTimer = 1500 * acceleration;
  const veryLongTimer = 2500 * acceleration;

  const typeWriter = (text, i, fnCallback) => {
    if (i < text.length && typingStatus === "typing") {
      setWordAnimating(text.substring(0, i + 1));

      setTimeout(() => {
        typeWriter(text, i + 1, fnCallback);
      }, fastTimer);
    } else if (i === text.length) {
      typingStatus = "typed";

      setTimeout(() => {
        setWordAnimating(text.substring(0, i - 1));
      }, longTimer);

      setTimeout(() => {
        typeWriter(text, i - 1, fnCallback);
      }, longTimer);
    } else if (i > 0 && typingStatus === "typed") {
      setWordAnimating(text.substring(0, i - 1));

      setTimeout(() => {
        typeWriter(text, i - 1, fnCallback);
      }, veryFastTimer);
    }
    // text finished, call callback if there is a callback function
    else if (typeof fnCallback === "function") {
      typingStatus = "typing";
      setTimeout(fnCallback, longTimer);
    }
  };

  const startTextAnimation = () => {
    wordPosition =
      wordPosition < wordsToAnimate.length - 1 ? wordPosition + 1 : 0;

    typeWriter(wordsToAnimate[wordPosition], 0, () => {
      startTextAnimation();
    });
  };

  useEffect(() => {
    const firstWord = wordsToAnimate[0];
    // @ts-ignore
    setWordAnimating(firstWord);
    setTimeout(() => {
      let i = firstWord.length;

      function myLoop() {
        setTimeout(() => {
          // @ts-ignore
          setWordAnimating(firstWord.substring(0, i - 1));
          i -= 1;
          if (i > 0) {
            myLoop();
          } else {
            setTimeout(() => {
              startTextAnimation();
            }, longTimer);
          }
        }, veryFastTimer);
      }

      myLoop();
    }, veryLongTimer);
  }, []);

  return (
    <Container>
      <Text>
        {wordAnimating}
        <TextCursor aria-hidden="true" />
      </Text>
    </Container>
  );
};

export default HomeBannerAnimation;
